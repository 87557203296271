import React from "react"

export const Footer = () => {
  return (
    <>
      <footer>
        <p>Copyright ©GOTDIAL all rights reserved. Powered by DIGI10.SOLUTION.</p>
      </footer>
    </>
  )
}
